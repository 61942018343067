import React, {
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { useRouter } from "next/router";

import { DocConfig } from "@config/nav";
import { SettingsContext } from "@components/settings/SettingsProvider";
import BotControl from "@components/BotControl";
import { MultiSelect } from "@components/doc/MultiSelect";
import { guaranteeArray, stringToURI } from "@scripts/utils";
import {
  currentOptions,
  fullyConfigured,
  pathsToOptionCombinations,
  Selections,
  selectionsFromSlugs,
  selectionsFromUrl,
  slugsFromSettings,
} from "@scripts/SlugPickerUtils";
import isEqual from "lodash/isEqual";
import theme from "@config/theme";
import CategorySelect from "./CategorySelect";

interface SlugPickerProps extends PropsWithChildren {
  config: DocConfig;
  reverse?: boolean;
  text?: string;
  categories?: boolean;
}

const debug = false;
const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

const SlugPicker = ({
  children,
  config,
  reverse,
  text,
  categories,
}: SlugPickerProps) => {
  const router = useRouter();
  const localSettings = useContext(SettingsContext);

  const { slugs } = config;
  let selectionOrder = slugs;

  const selectedFromUrl = selectionsFromUrl(
    guaranteeArray(router.query?.slugs),
    config,
  );
  const { mostRecentlySelected } = localSettings;
  const selectedFromSettings = selectionsFromSlugs(
    slugs,
    slugsFromSettings(config.allPaths, mostRecentlySelected),
  );
  const selectedFromDefault = selectionsFromSlugs(
    slugs,
    config.defaultSlugVals,
  );

  const initiallySelected = [
    selectedFromUrl,
    selectedFromSettings,
    selectedFromDefault,
  ].find(fullyConfigured);

  const [selected, setSelected] = useState(selectedFromUrl);

  if (reverse) {
    selectionOrder = [...selectionOrder].reverse();
  }

  const maybeRedirect = (newSelections: Selections) => {
    const shouldRedirect =
      fullyConfigured(newSelections) &&
      !isEqual(selectedFromUrl, newSelections);

    if (shouldRedirect) {
      // Change pathname while keeping hash, query, etc.
      const url = new URL(window.location.href);
      const { pathBase } = config;
      const pathSlugs = Object.values(newSelections).join("/");
      url.pathname = `${pathBase}/${pathSlugs}`;
      router.replace(url);
      setSelected(newSelections);
    }
  };

  useEffect(() => {
    maybeRedirect(initiallySelected!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initiallySelected), JSON.stringify(router.query?.slugs)]);

  const onChange = (key: string, value: string, isGrayedOut = false) => {
    let valueURI;

    // check if selected value has "latest" in it
    // normalize displayed string in dropdown onchange to ensure correct URI is subbed in
    const normalizedValue = value.toLowerCase();
    if (normalizedValue.includes("latest")) {
      valueURI = "latest";
    } else {
      // otherwise convert the string to a matching URI as normal
      valueURI = stringToURI(value);
    }

    debugLog("valueURI", valueURI);
    let clearedSelections = {};

    if (isGrayedOut) {
      clearedSelections = Object.fromEntries(
        selectionOrder.map((k) => [k, null]),
      );
      debugLog("clearedSelections", clearedSelections);
    }

    const { updateSelections } = localSettings;
    updateSelections([valueURI]);

    const newSelected = {
      ...selected,
      ...clearedSelections,
      [key]: valueURI,
    };

    debugLog("newSelected", newSelected);
    maybeRedirect(newSelected);
    setSelected(newSelected);
  };

  const combos = pathsToOptionCombinations(config.allPaths, slugs);
  const optionsForSelect = currentOptions(combos, selected, selectionOrder);

  const showPageContent = fullyConfigured(selected);

  const allowSearch = showPageContent !== config.searchOnlyDefaultSlugs;

  return (
    <BotControl
      allowChildren={showPageContent}
      allowSearchSiblings={allowSearch}
    >
      {!showPageContent && <div className="overlay" />}
      {children}
      <div className="picker">
        {categories ? (
          <CategorySelect
            onChange={onChange}
            options={optionsForSelect}
            selected={selected}
            text={text}
          />
        ) : (
          <MultiSelect
            onChange={onChange}
            options={optionsForSelect}
            selected={selected}
            text={text}
          />
        )}
      </div>
      <style jsx>
        {`
          .picker {
            z-index: ${theme.zIndex.rating + 2};
            position: ${!fullyConfigured(selected) ? "sticky" : "static"};
            top: 200px;
            clear: both;
          }
          .overlay {
            position: fixed;
            width: 200vw;
            height: 100%;
            top: 0;
            right: -50vw;
            background: linear-gradient(
              180deg,
              ${theme.colors.notehubBlue}00 0%,
              ${theme.colors.notehubBlue}B0 250px,
              ${theme.colors.notehubBlue}B0 100%
            );
            z-index: ${theme.zIndex.rating + 1};
          }
        `}
      </style>
    </BotControl>
  );
};

SlugPicker.defaultProps = {
  text: "Get started with:",
  reverse: true,
  categories: false,
};

export default SlugPicker;

import { MenuItem } from "@components/layout/Menu";
import { stringToURI } from "@scripts/utils";
// TODO: refactor this when [[...slugs]].tsx is refactored
//       - remove below properties used in DocMeta type in config/options.ts
type DocConfig = {
  allPaths: string[]; // all possible prop combinations (urls) for this document from meta.json
  defaultSlugVals: Array<string>; // e.g. [ 'notecarrier-al', 'arduino', 'python' ],
  description: string; // description of doc used for search engine purposes
  keywords: string[]; // search keywords
  navTitle: string; // title of doc
  pathBase: string; // e.g. '/foo/bar/baz'
  // searchOnlyDefaultSlugs tell the search indexer to only index the contents
  // of the page at the default slugs. This setting is useful for pages that
  // have multiple slugs that are not different enough to warrant separate
  // search results.
  searchOnlyDefaultSlugs?: boolean;
  slugs: string[]; // e.g. [ 'notecarrier', 'board', 'language' ],
};

// top header nav menu items
const HeaderMenuData: MenuItem[] = [
  {
    level: 1,
    selected: false,
    type: "link",
    title: "Shop",
    href: "https://shop.blues.com",
    items: [],
  },
  {
    level: 1,
    selected: false,
    type: "link",
    title: "Docs",
    href: "/docs",
    items: [],
  },
];

// galaxy titles to format galaxy key strings ("tools-and-sdks" => "Tools and SDKs"), etc
const GalaxyTitles = [
  "Quickstart",
  "Guides & Tutorials",
  "Notecard",
  "Notehub",
  "LoRa",
  "Starnote",
  "Feather MCUs",
  "API Reference",
  "Datasheets",
  "Tools & SDKs",
  "Example Apps",
  "FAQ",
];

const galaxyTitleToURI = (g: string) =>
  `/${g === "Get Started" ? "start" : stringToURI(g)}`;

// main menu data
const MainMenuData: MenuItem = {
  level: 0,
  selected: true,
  title: "Main Menu",
  type: "main",
  href: "/",
  items: GalaxyTitles.map((galaxy) => ({
    level: 1,
    selected: false,
    type: "galaxy",
    title: galaxy,
    href: `/${stringToURI(galaxy)}`,
    items: [],
  })),
};

const testable = { galaxyTitleToURI };

export { HeaderMenuData, MainMenuData, testable };
export type { DocConfig };

import { ReactNode } from "react";

interface BotControlProps {
  children: ReactNode;
  allowChildren: boolean;
  allowSearchSiblings?: boolean;
}

const BotControl = ({
  children,
  allowChildren,
  allowSearchSiblings,
}: BotControlProps) => (
  <div
    className="bot-control"
    aria-hidden={!allowChildren || undefined}
    data-search-exclude={!allowChildren || undefined}
    data-search-exclude-siblings={!allowSearchSiblings || undefined}
  >
    {children}
  </div>
);

BotControl.defaultProps = {
  allowSearchSiblings: true,
};

export default BotControl;

import * as React from "react";
import { Tabs, TabContent } from "@components/doc/Tabs";
import theme from "@config/theme";
import { ExampleRequestsContext } from "./ExampleRequests";

type CodeTabsContent = {
  children: React.ReactNode;
  exampleRequestTitle: string;
};

const CodeTabs = ({ children, exampleRequestTitle }: CodeTabsContent) => {
  const codeTabs = (
    <>
      <div className="codeTabs">
        <Tabs /* language selection tabs */>{children}</Tabs>{" "}
      </div>
      <style jsx>{`
        .codeTabs {
          border-radius: 4px;
          overflow: hidden;
          width: 100%;
          margin-bottom: 1em;
        }

        .codeTabs :global(.tab-label) {
          color: ${theme.colors.gray};
          background-color: ${theme.colors.gray1};
          padding: 1rem;
          font-weight: bold;
          font-size: ${theme.fonts.size.px16};
          user-select: none; // disable text selection
          border-bottom: 2px solid ${theme.colors.gray2};
          border-left: none;
          border-right: none;
          margin: -0.5px; // workaround tiny gap on google chrome
          text-align: center;
        }

        .codeTabs :global(.tabs-context) {
          border: 0;
        }
        .codeTabs :global(.tabs-context > .tab-content) {
          padding: 0;
        }

        .codeTabs :global(.tab-label:not(:last-of-type)) {
          border-right: 2px solid ${theme.colors.gray2};
        }

        .codeTabs :global(.codeBlockContainer) {
          // no border top radius
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-top: 0;
        }

        .codeTabs :global(.tab-input:checked + .tab-label) {
          color: ${theme.colors.gray6};
        }
      `}</style>
    </>
  );

  const isInExampleRequestContext = React.useContext(ExampleRequestsContext);

  return isInExampleRequestContext ? (
    <TabContent title={exampleRequestTitle || "Example"}>{codeTabs}</TabContent>
  ) : (
    codeTabs
  );
};

export default CodeTabs;

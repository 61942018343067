/* eslint-disable react/destructuring-assignment */
import { HumanOptions } from "@scripts/utils";

type Props = {
  dimension: string;
  [key: string]: string;
};

// HumanOption helps turn option keys into human readable values. For example:
// <HumanOption dimension={'language'} board={'esp32'} language={'c-cpp'}/>
// should return 'C/C++' because the caller used `dimension=language` to tell
// use they want the human readable version of the language property. In the
// event of an error we will console.log() some details and return dimension.
const HumanOption = (props: Props): React.ReactElement => {
  const { dimension } = props;
  const optionKey = props[dimension];
  let humanOption: string | undefined;
  humanOption = HumanOptions[dimension][optionKey];
  if (humanOption === undefined) {
    console.error(
      `<HumanOption>: humanOption == undefined:  dimension=${dimension} optionKey=${optionKey}`
    );
    return <>{dimension}</>;
  }
  return <>{humanOption}</>;
};

// PropsWrapped exists because for some reason <Board {...props} /> is not
// working in our current mdx parser. Instead <Board p={props} /> is the best we
// can do.  We unwrap it below.
type PropsWrapped = {
  p: {};
};

// Allow tech writers to use <Board p={props}/> to get 'ESP32'
export const Board = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="board" />
);
export const Category = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="category" />
);
export const Language = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="language" />
);
export const Notecard = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="notecard" />
);
export const Notecarrier = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="notecarrier" />
);
export const Service = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="service" />
);
export const QuickStartPath = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="quickstart_path" />
);
export const RouteSchema = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="route_schema" />
);
export const SwanDatasheetVersion = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="swan_datasheet_version" />
);
export const CygnetDatasheetVersion = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="cygnet_datasheet_version" />
);
export const AirnoteDatasheetVersion = (props: PropsWrapped) => (
  <HumanOption {...props.p} dimension="airnote_datasheet_version" />
);

export default HumanOption;
